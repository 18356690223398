module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="footer-left">\r\n        <div class="logo">\r\n            <a href="/">\r\n                <img src="' +
((__t = (require('./images/logo_footer.png'))) == null ? '' : __t) +
'" alt="">\r\n            </a>\r\n        </div>\r\n        <div class="text1"><a href="tel:+532-55579302">+532-55579302</a></div>\r\n        <div class="text2">\r\n            <p>人力资源服务</p>\r\n            <p>标准化的领先者</p>\r\n        </div>\r\n    </div>\r\n    <div class="footer-right">\r\n        <div class="cont">\r\n            <div class="left">\r\n                <a href="#">关于我们</a>\r\n                <a href="#">解决方案</a>\r\n                <a href="#">合作客户</a>\r\n                <a href="#">人才招聘</a>\r\n                <a href="#">新闻中心</a>\r\n                <a href="#">联系我们</a>\r\n            </div>\r\n            <div class="right">\r\n                <img src="' +
((__t = (require('./images/ewm.jpg'))) == null ? '' : __t) +
'" alt="">\r\n                <p>微信公众号</p>\r\n            </div>\r\n        </div>\r\n        <div class="copyright">\r\n            <p>Copyright © 2021 山东众合至诚人力资源有限公司 <span>版权所有</span></p>\r\n            <a href="javascript:void(0);" data-open="map">网站地图</a>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}