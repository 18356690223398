import './index.scss'

$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    // var bannerH = $('.ny-banner').height()
    //banner图滚动错位效果
    $('.ny-banner b').css({ 'transform': 'translateY('+ scroH / 2 + 'px)' });

    // if (scroH > bannerH){
    //     $('.ny-nav-mob').addClass('fixed')
    // }else{
    //     $('.ny-nav-mob').removeClass('fixed')
    // }
});