module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title1) var title1 = '';
if (!title2) var title2 = '';
;
__p += '\r\n<section class="ny-banner">\r\n    <b style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></b>\r\n    <div class="banner-title"><span>' +
((__t = ( title1 )) == null ? '' : __t) +
'</span>' +
((__t = ( title2 )) == null ? '' : __t) +
'</div>\r\n    <div class="banner-desc"><span>SCROLL DOWN</span></div>\r\n</section>';

}
return __p
}