module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title1) var title1 = '';
if (!title2) var title2 = '';
;
__p += '\r\n<section class="bottom-link">\r\n    <b class="bg-img" style="background-image: url(' +
((__t = (require('./images/bottom_link_bg.jpg'))) == null ? '' : __t) +
');"></b>\r\n    <div class="container">\r\n        <a href="#">\r\n            <div class="link1">继续访问</div>\r\n            <div class="link2">' +
((__t = ( title1 )) == null ? '' : __t) +
'</div>\r\n            <div class="link3">' +
((__t = ( title2 )) == null ? '' : __t) +
'</div>\r\n        </a>\r\n    </div>\r\n</section>';

}
return __p
}