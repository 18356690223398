module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <div class="header">\r\n        <div class="header-logo">\r\n            <a href="/">\r\n                <img class="red" src="' +
((__t = (require('./images/logo.png'))) == null ? '' : __t) +
'" alt="">\r\n                <img class="white" src="' +
((__t = (require('./images/logo_white.png'))) == null ? '' : __t) +
'" alt="">\r\n            </a>\r\n        </div>\r\n        <div class="header-nav">    \r\n            <a href="/" class="active">首页</a>\r\n            <a href="#">关于我们</a>\r\n            <a href="#">解决方案</a>\r\n            <a href="#">合作客户</a>\r\n            <a href="#">人才招聘</a>\r\n            <a href="#">新闻中心</a>\r\n            <a href="#">联系我们</a>\r\n        </div>\r\n    </div>\r\n    <div class="open-menu white">\r\n        <div class="hamburger hamburger-11" data-open="cmenu">\r\n            <div></div>\r\n        </div>\r\n    </div>\r\n    <div class="mob-menu">\r\n        <div>\r\n            <a href="/"><span class="cn">首页</span><span class="en">HOME</span></a>\r\n            <a href="#" class="active"><span class="cn">关于我们</span><span class="en">ABOUT US</span></a>\r\n            <a href="#"><span class="cn">解决方案</span><span class="en">BUSINESS AREA</span></a>\r\n            <a href="#"><span class="cn">合作客户</span><span class="en"> PARTER</span></a>\r\n            <a href="#"><span class="cn">人才招聘</span><span class="en">JOIN US</span></a>\r\n            <a href="#"><span class="cn">新闻中心</span><span class="en">NEWS CENTER</span></a>\r\n            <a href="#"><span class="cn">联系我们</span><span class="en">CONTACT</span></a>\r\n        </div>\r\n    </div>\r\n</header>';

}
return __p
}