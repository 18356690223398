import './index.scss'

$(document).scroll(function () { 
    var scroH = $(document).scrollTop();  
    var viewH = $(window).height();
    var contentH = $(document).height();
    var bannerH = $('.index-banner').height() || $('.ny-banner').height() || $('.solution-banner').height() || 0
    if (scroH > bannerH / 4) {
        $('.header').addClass('scroll-hidden')
    } else {
        $('.header').removeClass('scroll-hidden')
    }   
    if (scroH > bannerH - 100){  
        $('header .open-menu').removeClass('white')
    }else{
        $('header .open-menu').addClass('white')
    }

    //底部链接图片错位效果
    if ($('.bottom-link').length > 0) {
        var bottomlinkTop = $('.bottom-link').offset().top;
        if (scroH + viewH >= bottomlinkTop) {
            $('.bottom-link .bg-img').css('transform', 'translateY(-' + (scroH - bottomlinkTop + viewH) / 10 + 'px)')
        }
    }
    if ($('.solution-bottom-link').length > 0) {
        var bottomlinkTop = $('.solution-bottom-link').offset().top;
        if (scroH + viewH >= bottomlinkTop) {
            $('.solution-bottom-link .bg-img').css('transform', 'translateY(-' + (scroH - bottomlinkTop + viewH) / 12 + 'px)')
        }
    }

});


$('header .open-menu').on('click', function () {
    if ($(this).hasClass('active')) {
        $(this).removeClass('active')
        $('header .hamburger').removeClass('active')
        $('header .mob-menu').removeClass('active')
    } else {
        $(this).addClass('active')
        $('header .hamburger').addClass('active')
        $('header .mob-menu').addClass('active')
    }
})

