
// import {WOW} from '../../../node_modules/wowjs/dist/wow';
import './jquery.nicescroll.min'

if (window.devicePixelRatio < 2) {
  if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || window.devicePixelRatio < 2) {
    $('body').niceScroll({
      mousescrollstep: 40,
      scrollspeed: 80,
      zindex: 101,
      cursorcolor: '#d70545',
      cursorborder: '1px solid #d70545',
      cursorwidth: '5px',
      cursoropacitymax: 0.6,
      smoothscroll: true,
      iframeautoresizeL: true,
    });
  }
}


// new WOW().init();


//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > 50) { //距离顶部大于140px时
    $('.root-header').addClass('scroll-header')
  } else {
    $('.root-header').removeClass('scroll-header')
  }
  if (contentH - (scroH + viewH) <= 100) { //距离底部高度小于100px

  }
  if (contentH = (scroH + viewH)) { //滚动条滑到底部啦

  }

});

//页面滚动监听
// var isScroll = false;
// var beforeScroH = $(document).scrollTop();
// $(document).scroll(function () {
//   var scroH = $(document).scrollTop(); //滚动高度
//   var viewH = $(window).height(); //可见高度
//   var contentH = $(document).height(); //内容高度
//   var bannerTop = $('.index-news').offset().top;

//   //判断页面是上滚还是下滚
//   var delta = scroH - beforeScroH;
//   if (window.screen.width > 1200){
//     if (scroH == 0) {
//       isScroll = false;
//     }
//     if (delta > 0) {
//       if (scroH > 2 && scroH < bannerTop / 2 && isScroll == false) {
//         isScroll = true;
//         $('html ,body').animate({
//           scrollTop: $('.index-news').offset().top - 100
//         }, 300);
//         return false;
//       }
//     }
//     if (delta < 0) {
//       if (scroH < bannerTop && scroH > bannerTop / 2 && isScroll == true) {
//         isScroll = false;
//         $('html ,body').animate({
//           scrollTop: 0
//         }, 300);
//         return false;
//       }
//     }
//   }
//   beforeScroH = scroH;
// });